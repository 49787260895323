export const formatTime = (time: string): string => {
    var d = new Date();
    d.setHours(0,0,0,0);

    d.setHours(parseInt(time.substring(0, 2)));
    d.setMinutes(parseInt(time.substring(3, 5)));
    
    return dateToTime(d);
}

export const dateToTime = (date: Date): string  => {
    return date.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});
}