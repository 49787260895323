import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { search } from '../../features/conciergeSlice';
import styles from './LoadingSpinner.module.css';
import { isLoading } from '../../features/conciergeSlice';
import { Spinner } from '@fluentui/react';

export function LoadingSpinner() {
    const showSpinner = useAppSelector(isLoading);
    
    const spinnerStyles= {
        circle: {
          height: 100,
          width: 100,
          borderWidth: 6,
        }
      }

    return (
        <div className={styles.spinner} hidden={!showSpinner}>
            <div className={styles.innerSpinner}>
                <Spinner styles={spinnerStyles} />
            </div>
        </div>
    );
}
