import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class Data {
    private static instance: Data;
    private axiosInstance: AxiosInstance;

    private constructor() {

        const options: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        this.axiosInstance = Axios.create(options);
    }

    public static axiosInstance(): AxiosInstance {
        if (!Data.instance) {
            Data.instance = new Data();
        }

        return Data.instance.axiosInstance;
    }

    /*
    public static get<T>(url: string, body?: any): Promise<AxiosResponse<T>> {
        return DataLayer.axiosInstance()
            .get(url, { withCredentials: true })
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static async post(url: string, data?: any, options: AxiosRequestConfig = { withCredentials: true }): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .post(url, data, options)
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static put(url: string, data?: any): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .put(url, data, {})
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static delete(url: string): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .delete(url)
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }
    */
}