import React, { FormEventHandler, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { appData, loadExistingAppointments, search, setStatus } from '../../features/conciergeSlice';
import styles from './Search.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Statuses } from '../../features/statuses';
import { ButtonStyles, CenteredLabelStyles, ErrorLabelStyles, InputStyles, LabelStyles } from '../../Control.Styles';
import { addMonths, Label } from '@fluentui/react';

export function Search() {
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [ticketId, setTicketId] = useState('');


    const submit = (e: any) => {
        e.preventDefault();
        dispatch(search({ ticketId: ticketId, email: email }))
    }


    const toSchedule = () => {
        var start = addMonths(new Date(), -1);
        var end = addMonths(new Date(), 1);

        dispatch(loadExistingAppointments({ start: start, end: end }));
        dispatch(setStatus(Statuses.scheduleAppointment))
    }


    return (
        <div className={styles.search}>
            <div className={`${styles.row} ${styles.center}`}>
                <img
                    src="https://static.wixstatic.com/media/a98213_6a85163eed2e40feaf1978ae071be70d~mv2.png/v1/fill/w_101,h_91,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2021-Synergy-Logo-White.png"
                    alt="2021-Synergy-Logo-White.png"
                    srcSet="https://static.wixstatic.com/media/a98213_6a85163eed2e40feaf1978ae071be70d~mv2.png/v1/fill/w_101,h_91,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2021-Synergy-Logo-White.png 1x, https://static.wixstatic.com/media/a98213_6a85163eed2e40feaf1978ae071be70d~mv2.png/v1/fill/w_202,h_182,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2021-Synergy-Logo-White.png 2x"
                />
            </div>

            <div className={`${styles.row} ${styles.center}`}>
                <h3>Concierge Appointment Booking Tool</h3>
            </div>

            <div className={`${styles.row} ${styles.center}`}>
                <h2>WELCOME</h2>
            </div>

            <div className={styles.row}>
                <p>To use this tool you must have an existing ticket in Revelation helpdesk</p>
            </div>


            <form onSubmit={submit}>
                <div className={`${styles.row} ${styles.form}`}>
                    <Label styles={LabelStyles}>Please enter an existing ticket number:</Label>
                </div>
                <div className={`${styles.row} ${styles.form} ${styles.spaceBottom}`}>
                    <TextField
                        value={ticketId}
                        autoComplete='off'
                        styles={InputStyles}
                        placeholder='Ticket Number'
                        style={{ backgroundColor: 'transparent !impotant' }}
                        onChange={(e, v) => setTicketId(v ?? '')}
                    />
                </div>
                <div className={`${styles.row}`}>
                    <div className={`${styles.form}`}>
                        <Label styles={LabelStyles}>Please enter your email address:</Label>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.responsive} ${styles.between}`}>
                    <div className={`${styles.form}`}>
                        <TextField
                            value={email}
                            autoComplete='off'
                            styles={InputStyles}
                            placeholder='Email Address'
                            style={{ backgroundColor: 'transparent !impotant' }}
                            onChange={(e, v) => setEmail(v ?? '')}
                        />
                    </div>

                    <DefaultButton
                        text="Search"
                        type='submit'
                        className={styles.searchHere}
                        styles={ButtonStyles}
                    />
                </div>
            </form>

            <div hidden={useSelector((state: RootState) => state.concierge.status) !== Statuses.ticketNotFound}>
                <div className={styles.row}>
                    <Label styles={ErrorLabelStyles}>Sorry, we couldn't find that ticket. Please enter a valid Revelation ticket number and e-mail, and search again.</Label>
                </div>
            </div>

            <div hidden={useSelector((state: RootState) => state.concierge.status) !== Statuses.ticketFound}>
                <div className={styles.row}>
                    <p>Ticket found with the following ticket description:</p>
                </div>
                <div className={styles.row}>
                    <p>{useSelector((state: RootState) => state.concierge.appData.ticket.description)}</p>
                </div>
            </div>

            <div hidden={useSelector((state: RootState) => state.concierge.status) === Statuses.ticketFound}>
                <div className={`${styles.row} ${styles.responsive} ${styles.between} ${styles.margintop}`}>
                    <p>If you don't have an existing ticket</p>
                    <DefaultButton
                        text="Log A Ticket Here"
                        styles={ButtonStyles}
                        className={styles.logHere}
                        href='mailTo:helpdesk@synergyonline.com'
                    />
                </div>
            </div>

            <div hidden={useSelector((state: RootState) => state.concierge.status) !== Statuses.ticketFound}>
                <div className={`${styles.row} ${styles.margintop} ${styles.center}`}>
                    <DefaultButton
                        text="Schedule Appointment"
                        styles={ButtonStyles}
                        onClick={toSchedule}
                    />
                </div>
                <div className={`${styles.row} ${styles.center}`}>
                    <Label styles={CenteredLabelStyles}>or enter a different ticket number above </Label>
                </div>
            </div>
        </div>
    );
}
function setHeader(arg0: string) {
    throw new Error('Function not implemented.');
}

