import { Statuses } from "./statuses";

export interface ConciergeState {
    header: string,
    isLoading: boolean,
    status: number,
    appData: AppData,
    existingAppointments?: ExistingAppointment[],
    workingHours: WorkingHours[],
}

export interface AppData {
    hasBooking: boolean,
    booking: Booking,
    ticket: Ticket,
}

export interface Booking {
    id: string,
    contactEmail: string,
    contactName: string,
    contactPhone: string,
    customerId: string,
    serviceId: string
    notes: string,
    startDate: Date,
    endDate: Date,
}

export interface Ticket {
    email: string,
    ticketId: number,
    description: string,
}

export interface WorkingHours {
    id: string,
    day: string,
    timeSlots: TimeSlot[],
}

export interface TimeSlot {
    startTime: string,
    endTime: string,
}

export interface ExistingAppointment {
    serviceId: string,
    start: Date,
    end: Date,
}

export const InitialState: ConciergeState = {
    header: 'Schedule an appointment',
    isLoading: false,
    status: Statuses.start,
    appData: {
        hasBooking: false,
        booking: {
            contactEmail: '',
            contactName: '',
            contactPhone: '',
            customerId: '',
            id: '',
            notes: '',
            serviceId: '',
            startDate: new Date(),
            endDate: new Date(),
        },
        ticket: {
            description: '',
            ticketId: 0,
            email: '',
        }
    },
    workingHours: [],
};