import React, { useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { appData, appStatus, cancelAppointment, createAppointment, endDate, loadExistingAppointments, setEmail, setHeader, setName, setNotes, setPhone, setStatus, startDate } from '../../features/conciergeSlice';
import styles from './Appointment.module.css';
import { Statuses } from '../../features/statuses';
import { CancelAppointmentArgs } from '../../features/conciergeAPI';
import { ButtonStyles, InputStyles, MultilineInputStyles } from '../../Control.Styles';
import { dateToTime } from '../../features/consiergeLogic';
import { addMonths } from '@fluentui/react';

export function Appointment() {
    const dispatch = useAppDispatch();
    const _appData = useAppSelector(appData);
    const _startDate = useAppSelector(startDate);
    const _endDate = useAppSelector(endDate);
    const _appStatus = useAppSelector(appStatus);



    const submit = () => {
        var args = {
            userEmail: _appData.ticket.email,
            ticketId: _appData.ticket.ticketId,
            description: _appData.ticket.description,
            id: _appData.booking.id,
            contactEmail: _appData.booking.contactEmail,
            contactName: _appData.booking.contactName,
            contactPhone: _appData.booking.contactPhone,
            customerId: _appData.booking.customerId,
            serviceId: _appData.booking.serviceId,
            notes: _appData.booking.notes,
            startDate: _appData.booking.startDate,
            endDate: _appData.booking.endDate,
        };

        dispatch(createAppointment(args))
    }


    const reschedule = () => {
        var start = addMonths(_startDate, -1);
        var end = addMonths(_endDate, 1);

        dispatch(loadExistingAppointments({ start: start, end: end }));
        dispatch(setStatus(Statuses.scheduleAppointment))
    }


    const cancel = () => {
        var args: CancelAppointmentArgs = {
            bookingId: _appData.booking.id,
            ticketId: _appData.ticket.ticketId,
            userEmail: _appData.ticket.email
        };

        dispatch(cancelAppointment(args));
    }


    const discard = () => {
        if(!!_appData.booking.id) {
            dispatch(setStatus(Statuses.viewAppointment))
        }
        else {
            dispatch(setStatus(Statuses.scheduleAppointment))
        }
    }


    const parseDates = () => {
        if (!_appData?.booking?.startDate || !_appData?.booking?.endDate) return '';
        return `${_startDate.toLocaleDateString()} ${dateToTime(_startDate)} to ${dateToTime(_endDate)}`
    }

    return (
        <div className={styles.appointment}>
            <div className={styles.row}>
                <div className={`${styles.col} ${styles.firstCol} ${styles.left}`}>
                    <p>Revelation Ticket</p>
                </div>

                <div className={`${styles.col} ${styles.firstCol} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.ticket.ticketId.toString()}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField

                            value={_appData.ticket.ticketId.toString()}
                            styles={InputStyles}
                            style={{ backgroundColor: 'transparent' }}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Appointment</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {parseDates()}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            value={parseDates()}
                            styles={InputStyles}
                            style={{ backgroundColor: 'transparent' }}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Description</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.ticket.description}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            value={_appData.ticket.description}
                            styles={InputStyles}
                            style={{ backgroundColor: 'transparent' }}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Contact Name</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.booking.contactName}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            value={_appData.booking.contactName}
                            styles={InputStyles}
                            onChange={(e, v) => dispatch(setName(v ?? ''))}
                            disabled={_appStatus == Statuses.viewAppointment}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Contact Email</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.booking.contactEmail}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            value={_appData.booking.contactEmail}
                            styles={InputStyles}
                            onChange={(e, v) => dispatch(setEmail(v ?? ''))}
                            disabled={_appStatus == Statuses.viewAppointment}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Contact Phone</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.booking.contactPhone}
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            value={_appData.booking.contactPhone}
                            styles={InputStyles}
                            style={{ backgroundColor: 'transparent' }}
                            onChange={(e, v) => dispatch(setPhone(v ?? ''))}
                            disabled={_appStatus == Statuses.viewAppointment}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.col} ${styles.left}`}>
                    <p>Notes (Optional)</p>
                </div>

                <div className={`${styles.col} ${styles.right}`}>
                    <div hidden={_appStatus != Statuses.viewAppointment}>
                        <p>
                            {_appData.booking.notes}&nbsp;
                        </p>
                    </div>

                    <div className={styles.inputContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <TextField
                            multiline
                            value={_appData.booking.notes}
                            styles={MultilineInputStyles}
                            onChange={(e, v) => dispatch(setNotes(v ?? ''))}
                            disabled={_appStatus == Statuses.viewAppointment}
                        />
                    </div>
                </div>
            </div>

            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.left}`}>
                    &nbsp;
                </div>

                <div className={`${styles.col} ${styles.right} ${styles.buttonsRow}`}>
                    <div className={styles.buttonContainer} hidden={_appStatus == Statuses.viewAppointment || !_appData.booking.id || _appData.booking.id.length == 0}>
                        <DefaultButton
                            text="Cancel Appointment"
                            styles={ButtonStyles}
                            onClick={cancel}
                        />
                    </div>
                    <div className={styles.buttonContainer} hidden={_appStatus == Statuses.editAppointment}>
                        <DefaultButton
                            text="Edit"
                            styles={ButtonStyles}
                            onClick={() => dispatch(setStatus(Statuses.editAppointment))}
                        />
                    </div>
                    <div  className={styles.buttonContainer} hidden={_appStatus == Statuses.editAppointment}>
                        <DefaultButton
                            text="Reschedule"
                            styles={ButtonStyles}
                            onClick={reschedule}
                        />
                    </div>
                    <div  className={styles.buttonContainer} hidden={_appStatus == Statuses.editAppointment}>
                        <DefaultButton
                            text="Search Again"
                            styles={ButtonStyles}
                            onClick={() => dispatch(setStatus(Statuses.start))}
                        />
                    </div>
                    <div  className={styles.buttonContainer} hidden={_appStatus == Statuses.viewAppointment}>
                        <DefaultButton
                            text="Submit"
                            styles={ButtonStyles}
                            onClick={submit}
                        />
                    </div>
                    <div  className={styles.buttonContainer} hidden={_appStatus == Statuses.viewAppointment }>
                        <DefaultButton
                            text={!!_appData.booking.id ? "Discard" : "Reschedule"}
                            styles={ButtonStyles}
                            onClick={discard}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
