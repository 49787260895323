import { AxiosResponse } from "axios";
import { Data } from "../data/Data";
import { AppData, Booking, ExistingAppointment, WorkingHours } from "./ConciergeState";

export interface SearchArgs {
  ticketId: string,
  email: string,
}

export interface GetExistingAppointmentsArgs {
  start: Date,
  end: Date,
}

export interface ScheduleAppointmentArgs extends Booking {
  ticketId: number,
  userEmail: string,
  description: string,
}

export interface CancelAppointmentArgs {
  bookingId: string,
  ticketId: number,
  userEmail: string,
}

export async function fetch(args: SearchArgs): Promise<AxiosResponse<AppData>> {
  var getTicketUrl = process.env.GET_TICKET_URL || 'https://prod-12.westus.logic.azure.com:443/workflows/9c3d17451b2b4321abf0c1ddcad592e5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gDt7h6TPqpkvdVWK1y844GRdREbwj_bMkFeEaAdPBl8';

  return await Data.axiosInstance()
    .post(getTicketUrl, args);
}

export async function getWorkingHours(): Promise<AxiosResponse<WorkingHours[]>> {
  var getWorkingHoursUrl = process.env.GET_WORKING_HOURS_URL || 'https://prod-96.westus.logic.azure.com:443/workflows/6a7a0cf23f494228b084ee5489753b43/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Y7BYGAUv2lruo85Nql9lvqde9uraPbo0-j_kcAwfGeU';
  console.log(getWorkingHoursUrl);

  return await Data.axiosInstance()
    .get(getWorkingHoursUrl)
}

export async function getExisting(args: GetExistingAppointmentsArgs): Promise<AxiosResponse<ExistingAppointment[]>> {
  var getAppointmentsUrl = process.env.GET_APPOINTMENTS_URL || 'https://prod-172.westus.logic.azure.com:443/workflows/4b1a794c9fb64c339c618d40975ef074/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=IxwOXAT5chWkXNjUnhki3cOsLiHmwdkjSxpost6zkKY';

  return await Data.axiosInstance()
    .post(getAppointmentsUrl, args)
}

export async function schedule(args: ScheduleAppointmentArgs): Promise<AxiosResponse<Booking>> {
  var scheduleAppointmentsUrl = process.env.SCHEDULE_APPOINTMENT_URL || 'https://prod-68.westus.logic.azure.com:443/workflows/9854bd926ae2481ca7fdde893fa47732/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ieBp3VPFYgVfsXlyhU_J7EndiYNEmRVjYO2_-QbyOpo';

  return await Data.axiosInstance()
    .post(scheduleAppointmentsUrl, args)
}

export async function cancel(args: CancelAppointmentArgs): Promise<AxiosResponse<any>> {
  var cancelAppointmentsUrl = process.env.CANCEL_APPOINTMENT_URL || 'https://prod-176.westus.logic.azure.com:443/workflows/c99ea66e1e054c21a22e8445e4df7bb2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6l1rnSWndfgm5zfpJA_NZBZb9-DkJyQkC02_NrKmgLg';

  return await Data.axiosInstance()
    .post(cancelAppointmentsUrl, args)
}
