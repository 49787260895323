import React from 'react';
import styles from './App.module.css';
import { useSelector } from 'react-redux';
import { RootState } from './app/store';
import { Statuses } from './features/statuses';
import { Search } from './components/search/Search';
import { Schedule } from './components/schedule/Schedule';
import { LoadingSpinner } from './components/loadingSpinner/LoadingSpinner';
import { Appointment } from './components/appointment/Appointment';
import { useAppSelector } from './app/hooks';
import { appStatus } from './features/conciergeSlice';
import { Header } from './components/header/Header';

function App() {
  const _appStatus = useAppSelector(appStatus);
  
  return (
    <div className={styles.app}>
      <div className={styles.container} hidden={_appStatus >= Statuses.scheduleAppointment}>
        <Search />
      </div>
      <div className={styles.container} hidden={_appStatus != Statuses.scheduleAppointment}>
        <Schedule />
      </div>
      <div className={styles.container} hidden={_appStatus <= Statuses.scheduleAppointment}>
        <Appointment />
      </div>
      <LoadingSpinner />
    </div>
  );
}

export default App;
