import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetch, getExisting, GetExistingAppointmentsArgs, getWorkingHours, schedule, ScheduleAppointmentArgs, SearchArgs, cancel, CancelAppointmentArgs } from './conciergeAPI';
import { InitialState } from './ConciergeState';
import { Statuses } from './statuses';

const initialState = InitialState;

export const search = createAsyncThunk(
  'concierge/search',
  async (args: SearchArgs) => {
    const response = await fetch(args);
    return response.data;
  }
);

export const loadWorkingHours = createAsyncThunk(
  'concierge/loadWorkingHours',
  async () => {
    const response = await getWorkingHours();
    return response.data;
  }
);

export const loadExistingAppointments = createAsyncThunk(
  'concierge/loadExistingAppointments',
  async (args: GetExistingAppointmentsArgs) => {
    const response = await getExisting(args);
    return response.data;
  }
);

export const createAppointment = createAsyncThunk(
  'concierge/createAppointment',
  async (args: ScheduleAppointmentArgs) => {
    const response = await schedule(args);
    return response.data;
  }
);

export const cancelAppointment = createAsyncThunk(
  'concierge/cancelAppointment',
  async (args: CancelAppointmentArgs) => {
    const response = await cancel(args);
    return response.data;
  }
);

export const conciergeSlice = createSlice({
  name: 'concierge',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<number>) => {
      state.status = action.payload;
    },
    setService: (state, action: PayloadAction<string>) => {
      state.appData.booking.serviceId = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.appData.booking.contactName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.appData.booking.contactEmail = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.appData.booking.contactPhone = action.payload;
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.appData.booking.notes = action.payload;
    },
    setStart: (state, action: PayloadAction<Date>) => {
      state.appData.booking.startDate = action.payload;
    },
    setEnd: (state, action: PayloadAction<Date>) => {
      state.appData.booking.endDate = action.payload;
    },
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoading = false;
        state.appData = action.payload;
        console.log(action.payload);
        
        if (action.payload?.ticket?.ticketId == 0) 
          state.status = Statuses.ticketNotFound;
        else if (action.payload?.booking?.id && action.payload.booking.id.length > 0) 
          state.status = Statuses.viewAppointment;
        else 
          state.status = Statuses.ticketFound
      })
      .addCase(search.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(loadWorkingHours.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadWorkingHours.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workingHours = action.payload;
      })
      .addCase(loadWorkingHours.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(loadExistingAppointments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadExistingAppointments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.existingAppointments = action.payload;
      })
      .addCase(loadExistingAppointments.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.appData.booking = action.payload;
        state.status = Statuses.viewAppointment;
      })
      .addCase(createAppointment.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(cancelAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = Statuses.start;
      })
      .addCase(cancelAppointment.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setStatus, setService, setName, setPhone, setEmail, setNotes, setStart, setEnd, setHeader } = conciergeSlice.actions;

export const header = (state: RootState) => state.concierge.header;
export const appData = (state: RootState) => state.concierge.appData;
export const appStatus = (state: RootState) => state.concierge.status;
export const isLoading = (state: RootState) => state.concierge.isLoading;
export const workingHours = (state: RootState) => state.concierge.workingHours;
export const existingAppointments = (state: RootState) => state.concierge.existingAppointments;


export const startDate = (state: RootState) => {
  var date = state.concierge.appData.booking.startDate;
  if (date instanceof  Date) return date;
  if (typeof date === "string") return new Date(date);
  return new Date();
}

export const endDate = (state: RootState) => {
  var date = state.concierge.appData.booking.endDate;
  if (date instanceof  Date) return date;
  if (typeof date === "string") return new Date(date);
  return new Date();
}

export default conciergeSlice.reducer;
