import { IButtonStyles, ICalendarDayStyles, ICalendarStyles, ILabelStyles, ITextFieldStyles, Label } from "@fluentui/react";

const whiteProximaNova = {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontFamily: 'ProximaNovaFont',
}

export const LabelStyles: Partial<ILabelStyles> = {
    root: {
        ...whiteProximaNova,
        width: '100%',
        padding: 0,
    },
}

export const CenteredLabelStyles: Partial<ILabelStyles> = {
    root: {
        ...whiteProximaNova,
        padding: 0,
        marginBottom: '16px',
        textAlign: 'center',
    },
}

export const ErrorLabelStyles: Partial<ILabelStyles> = {
    root: {
        ...whiteProximaNova,
        color: '#FFF200',
    },
}


const inputStyles = {
    root: { 
        width: '100%',
    },
    field: {
        ...whiteProximaNova,
        margin: '0 15px',
        backgroundColor: 'transparent !important',
        selectors: {
            '::placeholder': {
                color: '#FFF',
                fontStyle: 'italic',
            }
        }
    },
    fieldGroup: {
        backgroundColor: 'transparent !important',
        borderRadius: '30px',
        border: '3px solid white',
        height: '60px',
        Width: '100%',
        selectors: {
            ':hover': {
                border: '3px solid white',
            },
            ':after': {
                border: 'none',
            },
        }
    },
}

export const InputStyles: Partial<ITextFieldStyles> = {
    root: inputStyles.root,
    field: inputStyles.field,
    fieldGroup: inputStyles.fieldGroup,
}

export const MultilineInputStyles: Partial<ITextFieldStyles> = {
    root: inputStyles.root,
    field: {
        ...inputStyles.field,
        padding: '20px 8px',
    },
    fieldGroup: {
        ...inputStyles.fieldGroup,
        height: '200px',
    },
}

export const ButtonStyles: Partial<IButtonStyles> = {
    root: {
        backgroundColor: '#A68FBA',
        borderRadius: '30px',
        border: 'none',
        height: '60px',
        color: 'white',
        fontSize: '1rem',
        margin: '0',
        width: '100%',
        padding: '0',
        maxWidth: '320px',
    },
    rootHovered: {
        backgroundColor: '#A68FBA',
        borderRadius: '30px',
        border: 'none',
        height: '60px',
        color: 'white',
    },
}

const monthButtonRoot = {
    backgroundColor: 'transparent',
    border: 'none',
    height: '60px',
    color: 'white',
    fontSize: '3rem',
    padding: '0 20px',
}

export const MonthButtonStyles: Partial<IButtonStyles> = {
    root: monthButtonRoot,
    rootHovered: monthButtonRoot,
    rootFocused: monthButtonRoot,
    rootPressed: monthButtonRoot,
    rootDisabled: { 
        ...monthButtonRoot, 
        color: 'rgba(255, 255, 255, 0.5)' 
    }
}

const slotButton = {
    margin: '8px',
    backgroundColor: 'transparent',
    borderRadius: '30px',
    borderWidth: '2px',
    height: '60px',
    width: '250px',
    fontSize: '1rem',
    padding: '0 20px',
}

export const SlotButtonStyles: Partial<IButtonStyles> = {
    root: {
        ...slotButton,
        borderColor: 'white',
        color: 'white',
    },
    rootHovered: {
        ...slotButton,
        borderColor: 'white',
        color: 'white',
    },
    rootDisabled: {
        ...slotButton,
        borderColor: 'rgba(100,100,100,0.5)',
        color: 'rgba(255,255,255,0.5)',
    }
}

export const CalendarStyles: Partial<ICalendarStyles> = {
    root: {
        width: '100%',
    },
    
}

const disabledDay = {
    color: '#A68FBA',
    fontSize: '1rem',
    backgroundColor: 'rgba(166, 143, 186, 0.5)',
}

const dateLine = {
    selectors: {
        ':before': {
            border: 'none',
        },
    }
}

export const CalendarDayStyles: Partial<ICalendarDayStyles> = {
    root: {
        width: '100%',
        padding: '0',
    },
    headerIconButton: {
        display: 'none',
    },
    monthAndYear: {
        color: 'white',
        fontSize: '1rem',
        textAlign: 'center',
        fontFamily: 'LuloCleanOneBold',
    },
    wrapper: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    dayCell: {
        color: 'white',
        width: '14%',
        lineHeight: '90px',
        fontSize: '1rem',
        selectors: {
            ':hover': {
                backgroundColor: 'rgba(166, 143, 186, 0.5)',
            },
        }
    },
    dayButton: {
        fontSize: '1rem',
    },
    daySelected: {
        border: 'none',
        backgroundColor: 'transparent',
        background: 'radial-gradient(circle at center,  rgba(166, 143, 186, 0.5) 0%,rgba(166, 143, 186, 0.5) 47%,rgba(166, 143, 186, 0.5) 47%,transparent 47%,transparent 48%)',
        selectors: {
            ':hover': {
                backgroundColor: 'rgba(166, 143, 186, 0.5) !important',
            },
        }
    },
    dayIsToday: {
        backgroundColor: 'transparent',
    },
    dayOutsideBounds: disabledDay,
    dayOutsideNavigatedMonth: disabledDay,
    datesAbove: dateLine,
    datesBelow: dateLine,
    datesRight: dateLine,
    datesLeft: dateLine
}