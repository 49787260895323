import styles from './Schedule.module.css';
import React, { useState } from 'react';

import { DefaultButton } from '@fluentui/react/lib/Button';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { TimeSlot, WorkingHours } from '../../features/ConciergeState';
import { Statuses } from '../../features/statuses';
import { addDays, addMonths, Calendar, DateRangeType, DayOfWeek, defaultCalendarStrings, getDateRangeArray, ICalendarDayProps, Label } from '@fluentui/react';
import { loadWorkingHours, setStatus, workingHours, existingAppointments, loadExistingAppointments, setStart, setEnd, setService, setHeader, appData } from '../../features/conciergeSlice';
import { ButtonStyles, CalendarDayStyles, CalendarStyles, CenteredLabelStyles, LabelStyles, MonthButtonStyles, SlotButtonStyles } from '../../Control.Styles';
import { formatTime } from '../../features/consiergeLogic';

const dateRangeType = DateRangeType.Month;
const firstDayOfWeek = DayOfWeek.Sunday;

export function Schedule() {
    const dispatch = useAppDispatch();

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedTime, setSelectedTime] = useState<TimeSlot>();

    const _appData = useAppSelector(appData);
    const _workingHours = useAppSelector(workingHours);
    const _existingAppointments = useAppSelector(existingAppointments);

    const weekdays: string[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];


    const getToday = (): Date => {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    const setDate = (date: Date): void => {
        setSelectedDate(date);
        setSelectedTime(undefined);
    }

    const goPrevious = React.useCallback((): void => {
        setSelectedDate(prevSelectedDate => {
            prevSelectedDate = prevSelectedDate || new Date();
            var subtractFrom = new Date(prevSelectedDate.getFullYear(), prevSelectedDate.getMonth(), 1);
            var newSelectedDate = addDays(subtractFrom, -1);

            setSelectedTime(undefined);
            dispatch(loadExistingAppointments({ start: addMonths(newSelectedDate, -1), end: newSelectedDate }));
            return newSelectedDate;
        });
    }, []);

    const goNext = React.useCallback((): void => {
        setSelectedDate(prevSelectedDate => {
            prevSelectedDate = prevSelectedDate || new Date();
            const dateRangeArray = getDateRangeArray(prevSelectedDate, dateRangeType, firstDayOfWeek);
            var newSelectedDate = addDays(dateRangeArray.pop()!, 1);

            setSelectedTime(undefined);
            dispatch(loadExistingAppointments({ start: newSelectedDate, end: addMonths(newSelectedDate, 1) }));
            return newSelectedDate;
        });
    }, []);


    const isSlotTaken = (wh: WorkingHours, slot: TimeSlot, slotDate: Date): boolean => {
        return !!_existingAppointments && _existingAppointments.filter(a => {
            var d = new Date(a.start);
            return wh.id == a.serviceId
                && slotDate?.getDate() == d.getDate()
                && slotDate?.getMonth() == d.getMonth()
                && d.getHours() == parseInt(slot.startTime.substring(0, 2))
                && d.getMinutes() == parseInt(slot.startTime.substring(3, 5));
        }).length > 0;
    }


    const isSlotPast = (slot: TimeSlot, slotDate: Date): boolean => {
        var d = new Date(slotDate || new Date());
        d.setHours(parseInt(slot.startTime.substring(0, 2)));
        d.setMinutes(parseInt(slot.startTime.substring(3, 5)));
        return new Date().getTime() > (d.getTime() - 5400000);
    }


    const isSlotSelected = (slot: TimeSlot): boolean => {
        return slot.startTime == selectedTime?.startTime;
    }


    const setTimeslot = (wh: WorkingHours, slot: TimeSlot): void => {
        setSelectedTime(slot);
        dispatch(setService(wh.id));
    }


    const selectedDateString = (slot?: TimeSlot): string => {
        var dateString = selectedDate?.toLocaleDateString() || '';
        if (slot == undefined) return dateString

        var startTime = formatTime(slot.startTime);
        var endime = formatTime(slot.endTime);

        return `${dateString} ${startTime} to ${endime}`;
    }


    const submit = () => {
        var start = selectedDate ? new Date(selectedDate) : new Date();
        var startTime = selectedTime?.startTime || '';
        start.setHours(parseInt(startTime.substring(0, 2)));
        start.setMinutes(parseInt(startTime.substring(3, 5)));

        var end = selectedDate ? new Date(selectedDate) : new Date();
        var endTime = selectedTime?.endTime || '';
        end.setHours(parseInt(endTime.substring(0, 2)));
        end.setMinutes(parseInt(endTime.substring(3, 5)));

        dispatch(setHeader('View/Edit appointment'));
        dispatch(setStart(start));
        dispatch(setEnd(end));
        dispatch(setStatus(Statuses.editAppointment));
    }


    const cancel = () => {
        if (!!_appData.booking.id) {
            dispatch(setStatus(Statuses.viewAppointment));
        }
        else {
            dispatch(setStatus(Statuses.start));
        }
    }


    const calendarDayProps: Partial<ICalendarDayProps> = {
        styles: CalendarDayStyles,
        customDayCellRef: (element, date, classNames) => {
            if (element) {
                var dayWorkingHours = _workingHours.filter(h => h.day == weekdays[date.getDay()]);
                for (var h of dayWorkingHours) {
                    for (var s of h.timeSlots) {
                        console.log(h.day);
                        if (!isSlotTaken(h, s, date) && !isSlotPast(s, date)) {
                            element.classList.add(styles.hasOpenSlots);
                            break;
                        }
                    }
                }
            }
        },
    };


    if (selectedDate == null) setDate(new Date());
    if (_workingHours.length == 0) dispatch(loadWorkingHours());

    const restrictedDates = (): Date[] => {
        var restrictedDates = [];
        const today = getToday()
        const date = selectedDate || new Date();

        for (var i = -35; i < 35; i++) {
            if (addDays(date, i).getMonth() != date.getMonth()
                || addDays(date, i) < today) {
                restrictedDates.push(addDays(date, i));
            }
        }
        return restrictedDates;
    }

    return (
        <div>
            <div className={styles.row}>
                <div className={`${styles.calendar}`}>
                    <div>
                        <p>Select a date and time for your meeting with one of our experts<br />
                            {selectedDateString(selectedTime)}</p>
                    </div>
                </div>

                <div className={`${styles.slots} ${styles.slotsTop}`}>

                </div>
            </div>
            <div className={styles.row}>
                <div className={`${styles.calendar}`}>
                    <div>
                        <Label styles={LabelStyles}>Select a date</Label>
                    </div>


                    <div className={styles.calendarButtons}>
                        <DefaultButton styles={MonthButtonStyles} onClick={goPrevious} text="<" disabled={getToday().getMonth() === selectedDate?.getMonth()} />
                        <DefaultButton styles={MonthButtonStyles} onClick={goNext} text=">" />
                    </div>

                    <div>
                        <Calendar
                            restrictedDates={restrictedDates()}
                            styles={CalendarStyles}
                            calendarDayProps={calendarDayProps}
                            isMonthPickerVisible={false}
                            highlightSelectedMonth
                            showGoToToday={false}
                            onSelectDate={(date: Date) => setDate(date)}
                            value={selectedDate}
                            strings={defaultCalendarStrings}

                        />
                    </div>
                </div>

                <div className={`${styles.slots}`}>
                    <div className={`${styles.slotsCol}`}>
                        <div>
                            <div>
                                <Label styles={CenteredLabelStyles}>Select a time<br /><span className={styles.zone}><em>all times are US Eastern</em></span></Label>
                            </div>
                            <div className={`${styles.slotButtons}`}>
                                {selectedDate != null ?
                                    _workingHours.filter(h => h.day == weekdays[selectedDate.getDay()])
                                        .map(h => {
                                            return (
                                                h.timeSlots?.map((s, i) => {
                                                    var selected = isSlotSelected(s);
                                                    var style = { backgroundColor: selected ? '#A68FBA' : 'transparent' };
                                                    return (
                                                        <div>
                                                            <DefaultButton
                                                                key={i}
                                                                style={style}
                                                                styles={SlotButtonStyles}
                                                                text={(formatTime(s.startTime))}
                                                                disabled={isSlotTaken(h, s, selectedDate) || isSlotPast(s, selectedDate)}
                                                                onClick={() => setTimeslot(h, s)}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            )
                                        }) : ''
                                }
                            </div>
                        </div>
                        <div className={styles.formButtons}>
                            <div>
                                <DefaultButton
                                    text="Schedule"
                                    styles={ButtonStyles}
                                    disabled={!selectedDate || !selectedTime}
                                    onClick={submit}
                                />

                            </div>

                            <div>
                                <DefaultButton
                                    text="Cancel"
                                    styles={ButtonStyles}
                                    onClick={cancel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

